document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#links-table').length) {
    table = $('#links-table').DataTable({
      ajax: $('#links-table').data('source'),
      order: [0, 'asc'],
      lengthMenu: [[-1], ["All"]],
      columns: [
        {
          data: "order",
          sortable: false,
          responsivePriority: 1,
          className: 'reorder'
        }, {
          data: "name",
          sortable: false,
          responsivePriority: 6
        }, {
          data: "location",
          sortable: false,
          responsivePriority: 3
        }, {
          data: "category",
          sortable: false,
          responsivePriority: 4
        }, {
          data: "created_at",
          sortable: false,
          responsivePriority: 5
        }, {
          data: "action",
          sortable: false,
          responsivePriority: 2
        }
      ],
      rowReorder: {
        snapX: true,
        selector: 'i.fas'
      }
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    table.on('row-reorder', function(e, diff, edit) {
      var result;
      result = $('#links-table tbody tr').map(function() {
        return this.id.replace(/link_/, '');
      }).get();
      return $.ajax({
        type: 'POST',
        url: $('#links-table').data('update'),
        data: {
          ids: result
        },
        async: false
      });
    });
    yadcf.init(table, [
      {
        column_number: 2,
        filter_container_id: 'td_2',
        omit_default_label: true,
        filter_reset_button_text: false,
        data: [
          {
            value: 1,
            label: 'Links'
          }, {
            value: 2,
            label: 'Frontpage'
          }, {
            value: 3,
            label: 'Partners page'
          }, {
            value: 4,
            label: 'Promo page'
          }, {
            value: 5,
            label: 'Collections page'
          }, {
            value: 6,
            label: 'Affiliate page'
          }, {
            value: 7,
            label: 'Join page'
          }, {
            value: 8,
            label: 'Landing page'
          }
        ]
      }, {
        column_number: 3,
        filter_container_id: 'td_3',
        data: $('#links-table').data('link-categories')
      }
    ]);
    yadcf.exFilterColumn(table, [[2, '1']]);
  }
  if ($('#inner-body.links.new, #inner-body.links.create, #inner-body.links.edit, #inner-body.links.update').length) {
    $("#link_location").change(function() {
      var v;
      v = $('#link_location').val();
      if (v === '1') {
        $('.link_link_category_id').show();
      } else {
        $('.link_link_category_id').hide();
      }
      if (v === '6') {
        $('#nav-banner-code').addClass('disabled-pointer disabled');
        $('.link_affiliates_location').show();
        $('.link_url').hide();
        $('.link_banner_url').hide();
        return $('#nav-upload banner a').tab('show');
      } else {
        $('#nav-banner-code').removeClass('disabled-pointer disabled');
        $('.link_affiliates_location').hide();
        $('.link_url').show();
        return $('.link_banner_url').show();
      }
    });
    $('#link_location').trigger('change');
    return $('#add-category').click(function() {
      var link_category;
      link_category = prompt("Please enter the name of the new category");
      if (link_category !== void 0 && link_category !== null && link_category !== '') {
        $('#link_link_category_id').append(new Option(link_category, ''));
        $('#link_link_category_id option:last').attr('selected', 'selected');
        return $('#link_link_category_attributes_name_en').val(link_category);
      }
    });
  }
});
