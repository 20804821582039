document.addEventListener('turbolinks:load', function() {
  var client, date, table, url;
  if ($('#debitorders-table').length) {
    table = $('#debitorders-table').DataTable({
      ajax: $('#debitorders-table').data('source'),
      order: [0, 'desc'],
      columns: [
        {
          data: "created_at",
          searchable: false
        }, {
          data: "client"
        }, {
          data: "payment_plan"
        }, {
          data: "payment_type"
        }, {
          data: "status"
        }, {
          data: "bill_id"
        }, {
          data: "price",
          searchable: true
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 1,
        filter_container_id: 'td_1',
        filter_type: 'auto_complete',
        filter_default_label: 'Select client'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_default_label: $('#debitorders-table').data('label-payment-plan'),
        data: $('#debitorders-table').data('payment-plan')
      }, {
        column_number: 3,
        filter_container_id: 'td_3',
        filter_default_label: $('#debitorders-table').data('label-payment-method'),
        data: $('#debitorders-table').data('payment-method')
      }, {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_default_label: $('#debitorders-table').data('label-status'),
        data: $('#debitorders-table').data('status')
      }
    ]);
    url = new URL(window.location);
    client = url.searchParams.get('client');
    if (client) {
      return yadcf.exFilterColumn(table, [[1, client]]);
    }
  } else if ($('#admins-debitorders-table').length) {
    table = $('#admins-debitorders-table').DataTable({
      ajax: $('#admins-debitorders-table').data('source'),
      order: [0, 'desc'],
      lengthMenu: [[10, 25, 50, 100, 2000], [10, 25, 50, 100, 2000]],
      columns: [
        {
          data: "id"
        }, {
          data: "paid_via"
        }, {
          data: "created_at"
        }, {
          data: "amount"
        }, {
          data: "approval",
          searchable: false
        }, {
          data: "chargeback",
          searchable: false
        }, {
          data: "refund",
          searchable: false
        }, {
          data: "comment"
        }
      ],
      dom: '<"datatable-toolbar">lrtip'
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    table.on('draw', function() {
      return table.columns('.sum').every(function() {
        var sum;
        sum = this.data().length === 0 ? 0 : this.data().reduce(function(a, b) {
          if (typeof a === 'string') {
            a = a.replace(/[^\d-]/g, '') * 1;
          }
          if (typeof b === 'string') {
            b = b.replace(/[^\d-]/g, '') * 1;
          }
          return a + b;
        });
        return $(this.footer()).html((sum / 100).toLocaleString($('html').attr('lang'), {
          style: 'currency',
          currency: 'EUR'
        }));
      });
    });
    yadcf.init(table, [
      {
        column_number: 1,
        filter_container_id: 'td_1',
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 3,
        filter_container_id: 'td_3',
        filter_type: 'text'
      }, {
        column_number: 4,
        filter_container_id: 'td_4',
        data: [
          {
            value: 1,
            label: 'yes'
          }, {
            value: 0,
            label: 'no'
          }
        ]
      }, {
        column_number: 5,
        filter_container_id: 'td_5',
        data: [
          {
            value: 1,
            label: 'yes'
          }, {
            value: 0,
            label: 'no'
          }
        ]
      }, {
        column_number: 6,
        filter_container_id: 'td_6',
        data: [
          {
            value: 1,
            label: 'yes'
          }, {
            value: 0,
            label: 'no'
          }
        ]
      }
    ]);
    date = new Date();
    date.setDate(0);
    yadcf.exFilterColumn(table, [
      [
        2, {
          from: '',
          to: date.toISOString().slice(0, 10)
        }
      ]
    ]);
    return $('#export-link').on('click', function(e) {
      var created_at, params;
      e.preventDefault();
      url = new URL($('#export-link').attr('href'));
      params = url.searchParams;
      created_at = yadcf.exGetColumnFilterVal(table, 2);
      params.set('payment_type', yadcf.exGetColumnFilterVal(table, 1));
      params.set('created_at', created_at['from'] + '-yadcf_delim-' + created_at['to']);
      params.set('price', yadcf.exGetColumnFilterVal(table, 3));
      params.set('approval', yadcf.exGetColumnFilterVal(table, 4));
      params.set('chargeback', yadcf.exGetColumnFilterVal(table, 5));
      params.set('refund', yadcf.exGetColumnFilterVal(table, 6));
      return Turbolinks.visit(url.toString());
    });
  }
});
