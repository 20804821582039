document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#dmcas-table').length) {
    table = $('#dmcas-table').DataTable({
      ajax: $('#dmcas-table').data('source'),
      order: [0, 'desc'],
      columns: [
        {
          data: "created_at",
          searchable: false
        }, {
          data: "identifier",
          sortable: false
        }, {
          data: "host",
          responsivePriority: 2
        }, {
          data: "source"
        }, {
          data: "count_of_links",
          searchable: false,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_default_label: $('#debitorders-table').data('label-host'),
        data: $('#debitorders-table').data('host')
      }
    ]);
  }
});
