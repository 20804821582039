document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#media-table').length) {
    table = $('#media-table').DataTable({
      ajax: {
        url: $('#media-table').data('source'),
        complete: function() {
          var error_img;
          error_img = $("#media-table").data('error-img');
          return $("#media-table").find('img').on('error', function() {
            return $(this).attr('src', error_img);
          });
        }
      },
      order: [1, 'desc'],
      dom: '<"datatable-toolbar">lrtip',
      columns: [
        {
          data: "name",
          responsivePriority: 1
        }, {
          data: "published_at",
          responsivePriority: 4
        }, {
          data: "collection_name",
          responsivePriority: 3
        }, {
          data: "meta_information",
          responsivePriority: 2,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'text'
      }, {
        column_number: 1,
        filter_container_id: 'td_1',
        filter_default_label: $('#media-table').data('label-state'),
        data: [
          {
            value: '!NULL',
            label: $('#media-table').data('label-published')
          }, {
            value: 'NULL',
            label: $('#media-table').data('label-unpublished')
          }
        ]
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_type: 'text'
      }
    ]);
  }
});
