document.addEventListener('turbolinks:load', function() {
  var initialize_doughnut_numbers, table, toggleField;
  toggleField = function(hideObj, showObj) {
    hideObj.hide();
    showObj.show();
    return showObj.find('input').focus();
  };
  initialize_doughnut_numbers = function(el) {
    var chart, currency, labels, locale, numbers;
    locale = $(el).data('locale');
    currency = $(el).data('currency');
    labels = $(el).data('labels');
    numbers = $(el).data('numbers');
    chart = new Chart(el, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: numbers,
            backgroundColor: ['rgba(153, 102, 255, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(202, 135, 80, 0.2)'],
            borderColor: ['rgba(153, 102, 255, 1)', 'rgba(255,99,132,1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(202, 135, 80, 1)'],
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var currentValue, dataset, date, index, percentage, total;
              dataset = data.datasets[tooltipItem.datasetIndex];
              index = tooltipItem.index;
              date = dataset.data[index].toLocaleString(locale, {
                style: 'currency',
                currency: currency
              });
              total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                return previousValue + currentValue;
              });
              currentValue = dataset.data[tooltipItem.index];
              percentage = Math.floor(currentValue / total * 100 + 0.5);
              return data.labels[index] + ': ' + date + ' (' + percentage + '%)';
            }
          }
        }
      }
    });
    return $(el).data('reference', chart);
  };
  if ($('.chartjs-doughnut').length) {
    $('.chartjs-doughnut').each(function() {
      return initialize_doughnut_numbers(this);
    });
    $('#revenue-share-calculation').on('change keyup', 'select, input', function() {
      var affiliate_revenue, affiliate_revenue_output, bank, bank_output, chart, cryptocurrency_discount, currency, data, gross, locale, merchant_affiliate_revenue, net_output, payout_output, shopmaker_revenue, shopmaker_revenue_output, shopmaker_revenue_vat, shopmaker_revenue_vat_output, vat, vat_output;
      locale = $('#revenue-share-calculation').data('locale');
      currency = $('#revenue-share-calculation').data('currency');
      gross = $('#gross').val();
      if (gross === 'customOption') {
        gross = $('#grossCustomGroup').find('input').val();
        toggleField($('#gross'), $('#grossCustomGroup'));
      }
      cryptocurrency_discount = $('#cryptocurrency_discount').val();
      if (cryptocurrency_discount > 0) {
        cryptocurrency_discount = gross * cryptocurrency_discount;
        gross = gross - cryptocurrency_discount;
        bank = 0;
      } else {
        bank = $('#bank').data('default');
      }
      $('#cryptocurrency_discount_output').html((-1 * cryptocurrency_discount).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      $('#bank').html(bank * 100 + '%');
      vat = $('#vat').val();
      shopmaker_revenue = $('#shopmaker_revenue').val();
      shopmaker_revenue_vat = $('#shopmaker_revenue_vat').val();
      affiliate_revenue = $('#affiliate_revenue').val();
      merchant_affiliate_revenue = $('#merchant_affiliate_revenue').val();
      vat_output = gross - gross / vat;
      $('#vat_output').html((-1 * vat_output).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      bank_output = gross * bank;
      $('#bank_output').html((-1 * bank_output).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      affiliate_revenue_output = (gross - bank_output) * affiliate_revenue;
      $('#affiliate_revenue_output').html((-1 * affiliate_revenue_output).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      net_output = gross - vat_output - bank_output - affiliate_revenue_output;
      $('#net_output').html(net_output.toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      shopmaker_revenue_output = net_output * shopmaker_revenue;
      $('#shopmaker_revenue_output').html((-1 * shopmaker_revenue_output).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      shopmaker_revenue_vat_output = shopmaker_revenue_output * shopmaker_revenue_vat;
      $('#shopmaker_revenue_vat_output').html((-1 * shopmaker_revenue_vat_output).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      payout_output = net_output - shopmaker_revenue_output - shopmaker_revenue_vat_output;
      $('#payout_output').html(payout_output.toLocaleString(locale, {
        style: 'currency',
        currency: currency
      }));
      data = [bank_output, affiliate_revenue_output];
      data.push(vat_output);
      data.push(shopmaker_revenue_output);
      if (shopmaker_revenue_vat !== '0') {
        data.push(shopmaker_revenue_vat_output);
      }
      data.push(payout_output);
      chart = $('#revenue-share-calculation-chart').data('reference');
      chart.data.datasets[0].data = data;
      return chart.update();
    });
    $('#gross').trigger('change');
  }
  if ($('#moneycounter').length) {
    $.get($('#moneycounter').data('url'), function() {
      var canvas;
      canvas = $('#moneycounter').find('canvas');
      if (canvas.length) {
        return initialize_doughnut_numbers(canvas);
      }
    });
  }
  if ($('#iframe-ga').length) {
    $('#iframe-ga').on('load', function() {
      return setTimeout(function() {
        return $('#load-ga').hide();
      }, 1000);
    });
  }
  if ($('#set-time-zone').length) {
    $.ajax({
      url: $('#set-time-zone').data('url'),
      type: 'patch',
      data: {
        merchant: {
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    });
  }
  if ($('#inner-body.shops.edit, #inner-body.shops.update').length) {
    $('#shop_theme').bind('change', (function(_this) {
      return function(event) {
        var url;
        url = $('#preview-theme-link').data('url') + '?theme=' + $('#shop_theme').val();
        return $('#preview-theme-link').attr('href', url);
      };
    })(this));
    $('.limit_length').each(function(i, span) {
      var allowed_length, textarea;
      allowed_length = $(span).data('length');
      textarea = $(span).closest('div').find('textarea, input');
      textarea.on('keyup', function() {
        var length;
        length = allowed_length - $(this).val().length;
        if (length < 0) {
          $(this).val($(this).val().substring(0, allowed_length));
          length = 0;
        }
        return $(span).html(length);
      });
      return textarea.keyup();
    });
    $('[data-toggle=popover]').popover();
    $('input#shop_stream_only').on('change', function() {
      if ($(this).is(':checked')) {
        return $('input#shop_stream_able').prop('checked', true);
      }
    });
    $('input#shop_stream_able').on('change', function() {
      if (!$(this).is(':checked')) {
        return $('input#shop_stream_only').prop('checked', false);
      }
    });
    $('#shop_collections_style').on('change', function() {
      var initial_value, selected_option;
      $('#shop_play_teaser_on_hover').attr('disabled', this.value !== 'grid');
      if (this.value === 'grid') {
        $('#shop_collections_per_page option').filter(function() {
          return this.value % 3 !== 0;
        }).attr('disabled', true);
        selected_option = $('#shop_collections_per_page option:selected:disabled');
        if (selected_option.length) {
          $('#shop_collections_per_page option').attr('selected', false);
          return selected_option.find('~ option:not(:disabled):first').attr('selected', true);
        }
      } else {
        $('#shop_collections_per_page option').attr('disabled', false);
        initial_value = $('#shop_collections_per_page').data('initial-value');
        $('#shop_collections_per_page option').attr('selected', false);
        return $('#shop_collections_per_page option[value=' + initial_value + ']').attr('selected', true);
      }
    });
    $('#shop_collections_style').trigger('change');
    $('#sortable_used_locales, #sortable_not_used_locales').sortable({
      connectWith: '.connected_list',
      placeholder: 'ui-state-highlight',
      update: function(event, ui) {
        var locales;
        locales = $('#sortable_used_locales li').map(function() {
          return $(this).data('locale');
        }).get().join(',');
        return $('#shop_locales').val(locales);
      }
    });
    if ($('#inner-body.is-admin').length) {
      $('.form-group label, .card-title').each(function() {
        return $('<i/>', {
          "class": 'fa fa-paragraph pl-1'
        }).insertAfter(this);
      });
      $('.fa-paragraph').click(function() {
        var element;
        element = $(this).prev('label');
        if (element.length) {
          return window.location.hash = element.attr('for').replace(/_\di$/, '');
        } else {
          element = $(this).closest('.card');
          if (element.length) {
            return window.location.hash = element.data('for');
          }
        }
      });
    }
  }
  if ($('#admins-shops-table').length) {
    table = $('#admins-shops-table').DataTable({
      ajax: $('#admins-shops-table').data('source'),
      order: [8, 'desc'],
      columns: [
        {
          data: "name",
          responsivePriority: 1,
        }, {
          data: "state",
        }, {
          data: "domain",
        }, {
          data: "last_update_at",
          orderSequence: ["desc", "asc", ""],
          responsivePriority: 3,
        }, {
          data: "readiness",
          orderSequence: ["desc", "asc", ""],
          responsivePriority: 2,
        }, {
          data: "rating_year",
          orderSequence: ["desc", "asc", ""],
        }, {
          data: "wants_creditcards",
          visible: false,
        }, {
          data: "has_creditcards",
          visible: false,
        }, {
          data: "created_at",
          orderSequence: ["desc", "asc", ""],
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 1,
        filter_container_id: 'top_td_1'
      }, {
        column_number: 5,
        filter_container_id: 'top_td_5',
      }, {
        column_number: 6,
        filter_container_id: 'top_td_6'
      }, {
        column_number: 7,
        filter_container_id: 'top_td_7'
      }
    ]);
  }
});

$(window).on('turbolinks:load hashchange', function() {
  var anchor, element;
  anchor = window.location.hash;
  if (anchor.length && /[a-z]/.test(anchor[1])) {
    element = $(anchor.replace('#', '.'));
    if (!element.length) {
      element = $('div[data-for=' + anchor.replace('#', '') + ']');
    }
    if (element.length) {
      element.addClass('box-highlight');
      $('html, body').animate({
        scrollTop: element.offset().top - $(window).outerHeight() / 2
      }, 'slow');
      return $('body').click(function() {
        return $(element).removeClass('box-highlight');
      });
    }
  }
});
