window.financial_statistics_details = function(index) {
  var uri;
  if ($('#financial-statistics-details').length) {
    $('#financial-statistics-details .fa-spin').show();
    uri = new window.URL($('#financial-statistics-details').data('url'));
    uri.pathname += '/' + index;
    return $.get(uri.toString());
  } else if ($('#financial-statistics-dashboard').length) {
    uri = new window.URL($('#financial-statistics-dashboard').data('url'));
    uri.searchParams.set('index', index);
    return Turbolinks.visit(uri.toString());
  }
};

document.addEventListener('turbolinks:load', function() {
  var index, table_collection, table_collection_el, table_model, table_model_el;
  if ($('#financial-statistics-details').length && (index = new URL(window.location).searchParams.get('index'))) {
    financial_statistics_details(index);
  }
  table_collection_el = $('#collection-statistics-table');
  if (table_collection_el.length) {
    table_collection = table_collection_el.DataTable({
      ajax: table_collection_el.data('source'),
      order: [3, 'desc'],
      paging: false,
      info: false,
      dom: 'tr',
      columns: [
        {
          data: "name",
          sortable: false
        }, {
          data: "published_at",
          sortable: false
        }, {
          data: "sales_count",
          orderSequence: ['desc']
        }, {
          data: "unlocked_collections_count",
          orderSequence: ['desc']
        }
      ]
    });
    table_collection.on('preDraw', function() {
      return table_collection_el.addClass('blur');
    });
    table_collection.on('draw', function() {
      return table_collection_el.removeClass('blur');
    });
    yadcf.init(table_collection, [
      {
        column_number: 1,
        filter_container_id: 'td_0',
        filter_default_label: table_collection_el.data('label-timeframe'),
        filter_reset_button_text: false
      }
    ]);
  }
  table_model_el = $('#model-statistics-table');
  if (table_model_el.length) {
    table_model = table_model_el.DataTable({
      ajax: table_model_el.data('source'),
      order: [3, 'desc'],
      paging: false,
      info: false,
      dom: 'tr',
      columns: [
        {
          data: "name",
          sortable: false
        }, {
          data: "collections_count",
          orderSequence: ['desc']
        }, {
          data: "sales_count",
          orderSequence: ['desc']
        }, {
          data: "unlocked_collections_count",
          orderSequence: ['desc']
        }
      ]
    });
    table_model.on('preDraw', function() {
      return table_model_el.addClass('blur');
    });
    return table_model.on('draw', function() {
      return table_model_el.removeClass('blur');
    });
  }
});
