Dropzone.autoDiscover = false;

document.addEventListener('turbolinks:load', function() {
  var el, element;
  element = $('#user-search');
  $('.user-menu').find('a').click(function() {
    return setTimeout((function() {
      return element.focus();
    }), 0);
  });
  if (element.length) {
    element.on('paste keyup', function(e) {
      var a, code;
      code = e.which;
      if (code === 13) {
        a = $('#user-list > a.hover:visible') || $('#user-list > a:visible:first');
        if (a.length) {
          return Turbolinks.visit(a.attr('href'));
        }
      } else if (code === 40) {
        a = $('#user-list > a.hover');
        if (a.length) {
          if (a.nextAll(':visible').length) {
            a.removeClass('hover');
            return a.nextAll(':visible').first().addClass('hover');
          }
        } else {
          a = $('#user-list > a:visible:first');
          return a.addClass('hover');
        }
      } else if (code === 38) {
        a = $('#user-list > a.hover');
        if (a.length) {
          if (a.prevAll(':visible').length) {
            a.removeClass('hover');
            return a.prevAll(':visible').first().addClass('hover');
          }
        } else {
          a = $('#user-list > a:visible:last');
          return a.addClass('hover');
        }
      } else {
        return setTimeout(function() {
          var value;
          value = element.val();
          if (value === '') {
            $('#user-list > a').show();
            return $('#user-list > a.via-ajax').remove();
          } else {
            return $.getJSON(element.data('url') + '&q=' + value, function(data) {
              var html;
              html = '';
              $('#user-list > a:not(.via-ajax)').hide();
              $('#user-list > a.via-ajax').remove();
              $.each(data, function(key, value) {
                return html += '<a href="' + value.path + '" class="dropdown-item via-ajax">' + value.name + '</a>';
              });
              $('#user-list').append(html);
              return $('#user-list > a:visible:first').addClass('hover');
            });
          }
        }, 1);
      }
    });
  }
  if ($('.nav-tabs-locale').length) {
    $('.nav-tabs-locale a[data-toggle="tab"]').on('click', function() {
      var el;
      el = $('.tab-content-locale').find($(this).attr('href'));
      return window.setTimeout(function() {
        return el.find('input, select, textarea').first().focus();
      }, 0);
    });
    el = $(".tab-content-locale input[value]:not([value='']):first");
    if (el.length) {
      $('.nav-tabs-locale a[href="#' + el.closest('.tab-pane').attr('id') + '"]').tab('show');
    } else {
      $('.nav-tabs-locale a:first').tab('show');
    }
  }
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });
  $.extend(true, $.fn.dataTable.defaults, {
    language: {
      url: $('#inner-body').data('datatables-localize-url')
    },
    stateSave: true,
    responsive: true,
    processing: true,
    serverSide: true,
    pageLength: 50
  });
  $.fn.DataTable.ext.pager.numbers_length = 18;
  if ($('.tinymce').length) {
    tinymce.remove();
    tinymce.init(Object.assign({
      selector: '.tinymce',
      language: $('html').attr('lang'),
      max_height: 500,
      width: '100%',
      menubar: false,
      branding: false,
      relative_urls: false,
      statusbar: false,
      images_upload_url: $('.tinymce').data('tinymce-images_upload_url'),
      file_picker_types: 'image',
      toolbar: 'undo redo | styles fontsize fontfamily | removeformat | forecolor backcolor | link unlink | image | pastetext | table | code',
      plugins: 'autolink autoresize image link code table',
      content_style: 'mark {background-color: #FA8072}',
      contextmenu: 'image link copy cut paste pastetext removeformat undo',
      init_instance_callback: function(editor) {
        if ($('#inner-body.wordfilter').length) {
          return editor.on('focus blur', function() {
            return editor.setContent(editor.getContent().replaceAll(/<mark>|<\/mark>/g, '').replaceAll(bad_words, function(matched) {
              return "<mark>" + matched + "</mark>";
            }));
          });
        }
      }
    }, $('.tinymce').data('tinymce')));
  }
  $.datepicker.setDefaults($.datepicker.regional[$('html').attr('lang')]);
  $('.sidebar-toggle-btn').PushMenu();
  return $('.copy-to-clipboard').on('click', function() {
    var input, replace_with;
    element = $(this);
    if (element.data('attr') === 'href') {
      input = this.appendChild(document.createElement("input"));
      input.value = $(element.data('copy')).attr('href');
      input.focus();
      input.select();
      document.execCommand('copy');
      input.parentNode.removeChild(input);
    } else {
      $(element.data('copy')).select();
      document.execCommand("copy");
    }
    replace_with = element.data('copy-replace-with');
    element.data('copy-replace-with', element.html());
    element.html(replace_with);
    element.focus();
    return setTimeout(function() {
      replace_with = element.data('copy-replace-with');
      element.data('copy-replace-with', element.html());
      return element.html(replace_with);
    }, 5000);
  });
});

document.addEventListener('turbolinks:before-cache', function() {
  return $($.fn.dataTable.tables()).each(function() {
    return $(this).DataTable().destroy();
  });
});
