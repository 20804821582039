var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

window.VideoJsWithRange = {
  call: function() {
    return $('.init-video').each(function() {
      var current_time, el, EndButton, remaining_time, StartButton, times, video, volume;
      el = $('<video>', $(this).data('attributes'));
      $.each($(this).data('sources'), function() {
        var source;
        source = $('<source>', this);
        return el.append(source);
      });
      el.insertBefore(this);
      video = videojs(el.attr('id'), {
        inactivityTimeout: 0
      });

      StartButton = (function(superClass) {
        extend(StartButton, superClass);

        function StartButton(player, options) {
          StartButton.__super__.constructor.call(this, player, options);
          this.addClass('fa');
          this.addClass('fa-step-backward');
          this.controlText("Start of teaser");
        }

        StartButton.prototype.handleClick = function() {
          var time;
          time = new Date(null);
          time.setSeconds(video.currentTime().toFixed());
          $('#collection_start_of_teaser').val(time.toISOString().substr(11, 8));
          if ($('#collection_start_of_teaser').val() > $('#collection_end_of_teaser').val()) {
            return $('#collection_end_of_teaser').val('');
          }
        };

        return StartButton;
      })(videojs.getComponent('Button'));
      videojs.registerComponent('StartButton', StartButton);
      video.getChild('controlBar').addChild('StartButton', {}, 15);

      EndButton = (function(superClass) {
        extend(EndButton, superClass);

        function EndButton(player, options) {
          EndButton.__super__.constructor.call(this, player, options);
          this.addClass('fa');
          this.addClass('fa-step-forward');
          this.controlText("End of teaser");
        }

        EndButton.prototype.handleClick = function() {
          var time;
          time = new Date(null);
          time.setSeconds(video.currentTime().toFixed());
          return $('#collection_end_of_teaser').val(time.toISOString().substr(11, 8));
        };

        return EndButton;
      })(videojs.getComponent('Button'));
      videojs.registerComponent('EndButton', EndButton);
      video.getChild('controlBar').addChild('EndButton', {}, 16);

      volume = videojs.utils.localStorage.getItem('volume');
      if (volume !== null) {
        video.volume(volume);
      }
      video.on('volumechange', function() {
        if (this.muted()) {
          volume = 0;
        } else {
          volume = this.volume();
        }
        return videojs.utils.localStorage.setItem('volume', volume);
      });
      current_time = el.parent().find('.vjs-current-time');
      remaining_time = el.parent().find('.vjs-remaining-time');
      current_time.detach().insertAfter(remaining_time);
      times = current_time.add(remaining_time);
      times.on('click', function() {
        times.toggle();
        if ($(this).hasClass('vjs-remaining-time')) {
          return videojs.utils.localStorage.setItem('show-time', 'current');
        } else {
          return videojs.utils.localStorage.removeItem('show-time');
        }
      });
      if (videojs.utils.localStorage.getItem('show-time') === 'current') {
        return remaining_time.click();
      }
    });
  }
};

document.addEventListener('turbolinks:before-cache', function() {
  return $('.video-js').each(function() {
    return videojs(this).dispose();
  });
});
