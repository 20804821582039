document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#newsletter_subscriptions-table').length) {
    table = $('#newsletter_subscriptions-table').DataTable({
      ajax: $('#newsletter_subscriptions-table').data('source'),
      order: [2, 'desc'],
      columns: [
        {
          data: "name",
          responsivePriority: 2
        }, {
          data: "email",
          responsivePriority: 1
        }, {
          data: "created_at",
          responsivePriority: 3
        }, {
          data: "deleted_at",
          responsivePriority: 4
        }, {
          data: "email_valid",
          responsivePriority: 4
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
