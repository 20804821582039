document.addEventListener('turbolinks:load', function() {
  if ($('#inner-body.social_media.index, #inner-body.shops.update').length) {
    return $('#sortable_used_segments, #sortable_not_used_segments').sortable({
      connectWith: '.connected_list',
      placeholder: 'ui-state-highlight',
      update: function(event, ui) {
        var segments;
        segments = $('#sortable_used_segments li').map(function() {
          return $(this).data('segment');
        }).get().join(',');
        return $('#shop_twitter_segments').val(segments);
      }
    });
  }
});
