document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#programs-table').length) {
    table = $('#programs-table').DataTable({
      ajax: $('#programs-table').data('source'),
      order: [0, 'desc'],
      columns: [
        {
          data: "subscriptions",
          responsivePriority: 5
        }, {
          data: "carts",
          responsivePriority: 6
        }, {
          data: "valid_from",
          responsivePriority: 3
        }, {
          data: "valid_till",
          responsivePriority: 4
        }, {
          data: "state",
          responsivePriority: 2
        }, {
          data: "action",
          responsivePriority: 1,
          sortable: false,
          searchable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_default_label: "Select state",
        data: ['active', 'pending', 'passive']
      }
    ]);
    yadcf.exFilterColumn(table, [[4, 'active']]);
  }
  if ($('#inner-body.programs.new, #inner-body.programs.create, #inner-body.programs.edit, #inner-body.programs.update').length) {
    return $('#activate_program_today').click(function() {
      var date;
      date = $('#activate_program_today').data('date').split('-');
      $('#program_activate_at_1i').val(parseInt(date[0]));
      $('#program_activate_at_2i').val(parseInt(date[1]));
      $('#program_activate_at_3i').val(parseInt(date[2]));
    });
  }
});
