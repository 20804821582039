document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#bills-table').length) {
    table = $('#bills-table').DataTable({
      ajax: $('#bills-table').data('source'),
      order: [0, 'desc'],
      columns: [
        {
          data: "name"
        }, {
          data: "valid_from"
        }, {
          data: "valid_till"
        }, {
          data: "paid_at"
        }, {
          data: "value"
        }, {
          data: "download",
          searchable: false,
          sortable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  } else if ($('#admins-bills-table').length) {
    table = $('#admins-bills-table').DataTable({
      ajax: $('#admins-bills-table').data('source'),
      order: [0, 'desc'],
      dom: 'lrtip',
      columns: [
        {
          data: "name",
          responsivePriority: 1
        }, {
          data: "shop"
        }, {
          data: "paid_via"
        }, {
          data: "valid_from"
        }, {
          data: "valid_till"
        }, {
          data: "value",
          responsivePriority: 3
        }, {
          data: "paid_at"
        }, {
          data: "action",
          searchable: false,
          sortable: false,
          responsivePriority: 2
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    table.on('draw', function() {
      return table.columns('.sum').every(function() {
        var sum;
        sum = this.data().length === 0 ? 0 : this.data().reduce(function(a, b) {
          if (typeof a === 'string') {
            a = a.replace(/[^\d-]/g, '') * 1;
          }
          if (typeof b === 'string') {
            b = b.replace(/[^\d-]/g, '') * 1;
          }
          return a + b;
        });
        return $(this.footer()).html((sum / 100).toLocaleString($('html').attr('lang'), {
          style: 'currency',
          currency: 'EUR'
        }));
      });
    });
    yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'text',
        style_class: 'yadcf-filter-100'
      }, {
        column_number: 1,
        filter_container_id: 'td_1',
        filter_type: 'text'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        data: [
          {
            value: 'bank',
            label: 'Bank'
          }, {
            value: 'paypal',
            label: 'Paypal'
          }
        ]
      }, {
        column_number: 3,
        filter_container_id: 'td_3'
      }, {
        column_number: 4,
        filter_container_id: 'td_4'
      }, {
        column_number: 5,
        filter_container_id: 'td_5'
      }, {
        column_number: 6,
        filter_container_id: 'td_6',
        data: [
          {
            value: '!NULL',
            label: 'Paid'
          }, {
            value: 'NULL',
            label: 'Not paid'
          }
        ]
      }
    ]);
    return $('#admins-bills-table').on('click', '.mark_as_paid', function() {
      var filter_value;
      filter_value = yadcf.exGetColumnFilterVal(table, 6);
      if (filter_value === 'NULL') {
        return setTimeout(function() {
          return yadcf.exFilterColumn(table, [[6, filter_value]], true);
        }, 2000);
      }
    });
  }
});
