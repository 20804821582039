window.add_default_params = function(url, node, on_save, name) {
  var TypeError, error, u;
  try {
    u = new URL(url);
    if ($.inArray(u.hostname, $('.tinymce').data('whitelist')) >= 0) {
      u.searchParams.set('utm_campaign', 'Newsletter');
      url = u.toString();
    }
  } catch (error) {
    TypeError = error;
  }
  return url;
};

document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#newsletters-table').length) {
    table = $('#newsletters-table').DataTable({
      ajax: $('#newsletters-table').data('source'),
      order: [2, 'desc'],
      columns: [
        {
          data: "subject",
          responsivePriority: 1
        }, {
          data: "recipients",
          responsivePriority: 4
        }, {
          data: "send_at",
          responsivePriority: 3
        }, {
          data: "state",
          responsivePriority: 2,
          searchable: false
        }, {
          data: "action",
          responsivePriority: 2,
          searchable: false,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
  if ($('#inner-body.newsletters.new, #inner-body.newsletters.create, #inner-body.newsletters.edit, #inner-body.newsletters.update').length) {
    return $("#newsletter_send_at_5i option[value!='00']").hide();
  }
});
