document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#payment_plans-table').length) {
    table = $('#payment_plans-table').DataTable({
      ajax: $('#payment_plans-table').data('source'),
      order: [1, 'asc'],
      columns: [
        {
          data: "name"
        }, {
          data: "price_cents"
        }, {
          data: "payment_plan"
        }, {
          data: "parameters",
          sortable: false
        }, {
          data: "state"
        }, {
          data: "action",
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_default_label: "Select state",
        data: ['active', 'pending', 'passive']
      }
    ]);
    return yadcf.exFilterColumn(table, [[4, 'active']]);
  } else if ($('#inner-body.payment_plans.new, #inner-body.payment_plans.create, #inner-body.payment_plans.edit, #inner-body.payment_plans.update').length) {
    $('#payment_plan_promo').on('paste keyup', function() {
      var element;
      element = $(this);
      return setTimeout(function() {
        var length;
        element.val(element.val().replace(/[^a-zA-Z0-9-]/g, ''));
        length = element.val().length;
        if (length >= 6 && length <= 20) {
          $('#special_link').attr('href', $('#special_link').data('url').replace('PROMO', element.val()));
          $('.copy-to-clipboard').show();
          return $('#payment_plan_use_at_promo').removeAttr('disabled');
        } else {
          $('#special_link').removeAttr('href');
          $('.copy-to-clipboard').hide();
          $('#payment_plan_use_at_promo').prop('checked', false);
          return $('#payment_plan_use_at_promo').attr('disabled', 'disabled');
        }
      }, 1);
    });
    return $('#payment_plan_promo').trigger('keyup');
  }
});
