global.$ = $; // So $ is available e.g. in app/helpers/statistics_helper.rb

require('@rails/ujs').start();
require('turbolinks').start();
require('bootstrap');
window.Cookies = require('js-cookie');
require('@hola.org/videojs-utils');
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
require('dropzone');

require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/datepicker'); // needed for datatables
require('jquery-ui/ui/i18n/datepicker-de.js');
require('jquery-ui/ui/effects/effect-highlight');

require('admin-lte/build/js/PushMenu.js');
require('admin-lte/build/js/Treeview.js');

require('datatables.net-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowreorder-bs4');
require('yadcf');

import {
    Chart, LinearScale, Title, DoughnutController, ArcElement, BarController, BarElement, CategoryScale, Tooltip,
    Legend, PieController
} from 'chart.js';
Chart.register(
    LinearScale, Title, DoughnutController, ArcElement, BarController, BarElement, CategoryScale, Tooltip,
    Legend, PieController
);
window.Chart = Chart;

require('jquery-tageditor/jquery.caret.min.js');
require('jquery-tageditor/jquery.tag-editor.js');

import * as Calendar from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap from '@fullcalendar/bootstrap';
import deLocale from '@fullcalendar/core/locales/de';
window.FullCalendar = Calendar;
window.FullCalendarDayGridPlugin = dayGridPlugin;
window.FullCalendarInteractionPlugin = interactionPlugin;
window.FullCalendarBootstrap = bootstrap;
window.FullCalendarDeLocale = deLocale;

require('./javascripts/merchants');
