window.ImagePoller = {
  poll: function() {
    window.image_poller_counter = 0;
    return window.image_poller_interval_id = setInterval(this.request, 5000);
  },
  request: function() {
    window.image_poller_counter = window.image_poller_counter + 1;
    if ($('.reload-img').length) {
      return $('.reload-img').each(function() {
        var img;
        if (window.image_poller_counter < 12) {
          img = $(this);
          return $('<img />').attr('src', img.data('url') + '?random=' + new Date().getTime()).on('load', function() {
            if (this.complete || typeof this.naturalWidth !== 'undefined' || this.naturalWidth !== 0) {
              img.attr('src', $(this).attr('src'));
              img.removeClass('reload-img');
              return clearInterval(window.image_poller_interval_id);
            }
          });
        } else {
          $(this).removeClass('reload-img');
          $(this).attr('src', $(this).data('error'));
          return clearInterval(window.image_poller_interval_id);
        }
      });
    } else {
      return clearInterval(window.image_poller_interval_id);
    }
  }
};

window.CollectionPreviewsPoller = {
  poll: function() {
    return setTimeout(this.request, 5000);
  },
  request: function() {
    return $.get($('#collection_preview_images').data('url'));
  }
};

window.CollectionTeasersPoller = {
  poll: function() {
    return setTimeout(this.request, 5000);
  },
  request: function() {
    return $.get($('#collection_teaser').data('url'));
  }
};

document.addEventListener('turbolinks:load', function() {
  var change_image_size, max_one_per_group, medium_id, table, url;
  max_one_per_group = function(el) {
    return el.on('change', ".max_one_in_group", function() {
      var group;
      group = $('.max_one_in_group[data-group-name=' + $(this).data('group-name') + ']:checked');
      if (group.length > 1) {
        return group.not(this).prop('checked', false);
      }
    });
  };
  change_image_size = function() {
    if (Cookies.get('img_xs') === 'big') {
      return $('#collections-table-flags').find('img').removeClass('img-xs');
    } else {
      return $('#collections-table-flags').find('img').addClass('img-xs');
    }
  };
  if ($('#collections-table').length) {
    table = $('#collections-table').DataTable({
      ajax: {
        url: $('#collections-table').data('source'),
        complete: function() {
          return ImagePoller.poll();
        }
      },
      order: [2, 'desc'],
      dom: 'lrtip',
      columns: [
        {
          data: "img",
          responsivePriority: 5,
          searchable: false,
          sortable: false
        }, {
          data: "name",
          responsivePriority: 1
        }, {
          data: "published_at",
          responsivePriority: 3
        }, {
          data: "meta_information",
          responsivePriority: 4,
          sortable: false,
          "class": 'meta-information-cell'
        }, {
          data: "action",
          responsivePriority: 2,
          searchable: false,
          sortable: false
        }, {
          data: "state",
          visible: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return yadcf.init(table, [
      {
        column_number: 1,
        filter_container_id: 'td_1',
        filter_type: 'text'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 3,
        filter_container_id: 'td_3b',
        filter_default_label: $('#td_3b').data('label')
      }, {
        column_number: 5,
        filter_container_id: 'td_3a',
        filter_default_label: $('#td_3a').data('label')
      }
    ]);
  } else if ($('#collections-table-flags').length) {
    table = $('#collections-table-flags').DataTable({
      ajax: {
        url: $('#collections-table-flags').data('source'),
        complete: function() {
          ImagePoller.poll();
          return max_one_per_group($('#collections-table-flags'));
        }
      },
      order: [2, 'desc'],
      dom: 'lrtip',
      columns: [
        {
          data: "img",
          responsivePriority: 9,
          searchable: false,
          sortable: false
        }, {
          data: "name",
          responsivePriority: 1
        }, {
          data: "published_at",
          responsivePriority: 4
        }, {
          data: "featured",
          responsivePriority: 3,
          searchable: false
        }, {
          data: "promotion",
          responsivePriority: 3,
          searchable: false
        }, {
          data: "hidden",
          responsivePriority: 3,
          searchable: false
        }, {
          data: "blurred",
          responsivePriority: 3,
          searchable: false
        }, {
          data: "upsell",
          responsivePriority: 3,
          searchable: false
        }, {
          data: "action",
          responsivePriority: 2,
          searchable: false,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 1,
        filter_container_id: 'top_td_1',
        filter_type: 'text'
      }, {
        column_number: 2,
        filter_container_id: 'top_td_2',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 3,
        filter_container_id: 'top_td_3'
      }, {
        column_number: 4,
        filter_container_id: 'top_td_4'
      }, {
        column_number: 5,
        filter_container_id: 'top_td_5'
      }, {
        column_number: 6,
        filter_container_id: 'top_td_6'
      }, {
        column_number: 7,
        filter_container_id: 'top_td_7'
      }
    ]);
    if (!$('#collections-table-flags').data('is-subscription-based')) {
      $('#outer_td_7').hide();
      table.column(7).visible(false);
    }
    $('#collections-table-flags').on('ajax:before', 'input[type=checkbox]', function() {
      var q;
      q = '';
      $(this).closest('tr').find('input[type=checkbox]').each(function() {
        return q += this.name + '=' + this.checked * this.checked + '&';
      });
      return this.setAttribute('data-params', q);
    });
    return $('#img-xs-container button').on('click', function() {
      Cookies.set('img_xs', $(this).data('img-xs'), {
        expires: 365
      });
      $('#img-xs-container button').removeClass('active');
      $(this).addClass('active');
      return change_image_size();
    });
  } else if ($('#full_calendar').length) {
    return new FullCalendar.Calendar(document.getElementById('full_calendar'), {
      plugins: [FullCalendarDayGridPlugin, FullCalendarInteractionPlugin, FullCalendarBootstrap],
      initialView: 'dayGridMonth',
      eventStartEditable: true,
      themeSystem: 'bootstrap',
      locales: [FullCalendarDeLocale],
      height: 'auto',
      timeZone: 'UTC',
      defaultTimedEventDuration: '00:01',
      events: $('#full_calendar').data('source'),
      locale: $('#full_calendar').data('locale'),
      eventContent: function(arg) {
        var img, title;
        title = document.createElement('span');
        title.innerHTML = arg.event._def.title;
        img = document.createElement('img');
        img.src = arg.event.extendedProps.image_url;
        return {
          domNodes: [title, img]
        };
      },
      eventDrop: function(info) {
        return $.post($('#full_calendar').data('update'), {
          id: info.event.id,
          published_at: info.event.start
        });
      }
    }).render();
  } else if ($('#inner-body.collections.new, #inner-body.collections.create, #inner-body.collections.edit, #inner-body.collections.update').length) {
    VideoJsWithRange.call();
    $('.datepicker-published_at').datetimepicker({
      inline: true,
      sideBySide: true,
      locale: $('html').attr('lang'),
      format: 'YYYY-MM-DD HH:mm',
      defaultDate: moment($('#collection_published_at').val(), 'YYYY-MM-DD HH:mm:ssZ')
    });
    $('#add-days').click(function() {
      return $('.datepicker-published_at').datetimepicker('date', moment($('#add-days').data('date'), 'YYYY-MM-DD HH:mm:ssZ'));
    });
    $('#link-to-available-models').click(function() {
      Cookies.set('link-to-models', 'available', {
        expires: 365
      });
      $('#link-to-available-models').addClass('active');
      $('#link-to-all-models').removeClass('active');
      return $(".collection_models span[data-available!='yes']").each(function() {
        if (!$(this).closest('div').find('input').is(':checked')) {
          return $(this).closest('div.form-check').hide();
        }
      });
    });
    $('#link-to-all-models').click(function() {
      Cookies.remove('link-to-models');
      $('#link-to-all-models').addClass('active');
      $('#link-to-available-models').removeClass('active');
      return $(".collection_models div.form-check").show();
    });
    if (Cookies.get('link-to-models') === 'available') {
      $('#link-to-available-models').click();
    }
    $('#link-to-my-categories').click(function() {
      Cookies.set('link-to-categories', 'my', {
        expires: 365
      });
      $('#link-to-my-categories').addClass('active');
      $('#link-to-all-categories').removeClass('active');
      return $(".collection_category_list span[data-my!='true']").each(function() {
        if (!$(this).closest('div').find('input').is(':checked')) {
          return $(this).closest('div.form-check').hide();
        }
      });
    });
    $('#link-to-all-categories').click(function() {
      Cookies.remove('link-to-categories');
      $('#link-to-all-categories').addClass('active');
      $('#link-to-my-categories').removeClass('active');
      return $(".collection_category_list div.form-check").show();
    });
    if (Cookies.get('link-to-categories') === 'my') {
      $('#link-to-my-categories').click();
    }
    max_one_per_group($('.max_one_in_group_container'));
    $(".collection_media input[type='checkbox']").change(function() {
      var checked_media, initial_media, initial_price, sum;
      if ($('#collection_set_default_price').is(':checked')) {
        initial_price = $('#collection_price_money').data('initial-price');
        sum = initial_price;
        $(".collection_media input[type='checkbox']:checked").each(function() {
          return sum += parseInt($(this).next().find('span').data('price'));
        });
        if (sum === initial_price) {
          $('#collection_price_money').val('');
        } else {
          if ($('#collection_price_money').data('round-to-99-cent')) {
            sum = parseInt(sum / 100) * 100 + 99;
          }
        }
        sum = (sum / 100).toLocaleString($('html').attr('lang'), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        if ($('#collection_price_original_money').val() === '') {
          $('#collection_price_money').val(sum);
        } else {
          $('#collection_price_original_money').val(sum);
        }
      }
      initial_media = $('.collection_recreate_preview_items').data('initial').sort().toString();
      checked_media = $('.collection_media input[type="checkbox"]:checked').map(function(i, e) {
        return e.value;
      }).sort().toArray().toString();
      if (initial_media === checked_media) {
        $('.collection_recreate_preview_items').find('input').prop('checked', false);
        if (!$('form#new_collection').length) {
          return $('.collection_recreate_preview_items').addClass('d-none');
        }
      } else {
        $('.collection_recreate_preview_items').find('input').prop('checked', true);
        if (!$('form#new_collection').length) {
          return $('.collection_recreate_preview_items').removeClass('d-none');
        }
      }
    });
    $('#collection_price_money').change(function() {
      var float;
      $('#collection_set_default_price').prop('checked', false);
      float = Number($(this).val().replace(',', '.'));
      if (isNaN(float) || float < 0) {
        return $(this).val('');
      }
    });
    $('#add-discount-link').on('click', function(e) {
      e.preventDefault();
      $('#add-discount-link').hide();
      $('.collection_price_original_money').show();
      $('#discount-links').show();
      $('#collection_price_original_money').val($('#collection_price_money').val());
      $('#collection_price_money').val('');
      $('#collection_price_money').focus();
      return $('.collection_discounted_until').show();
    });
    $('#remove-discount-link').on('click', function(e) {
      e.preventDefault();
      $('.collection_price_original_money').hide();
      $('#discount-links').hide();
      if ($('#collection_price_original_money').val() !== '') {
        $('#collection_price_money').val($('#collection_price_original_money').val());
      }
      $('#collection_price_original_money').val('');
      $('#collection_price_money').focus();
      $('#add-discount-link').show();
      $('.collection_discounted_until').hide();
      $('#collection_discounted_until_1i').val('');
      $('#collection_discounted_until_2i').val('');
      $('#collection_discounted_until_3i').val('');
      $('#collection_discounted_until_4i').val('');
      return $('#collection_discounted_until_5i').val('');
    });
    if ($('#collection_price_original_money').val() === '') {
      $('.collection_price_original_money').hide();
      $('#discount-links').hide();
      $('.collection_discounted_until').hide();
    } else {
      $('#add-discount-link').hide();
    }
    $('#discount-links a').on('click', function(e) {
      e.preventDefault();
      return $('#collection_price_money').val(Math.max(0.99, $('#collection_price_original_money').val().replace(',', '.') * $(this).data('volume')).toLocaleString($('html').attr('lang'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    });
    $('#set-date-links a').on('click', function(e) {
      var date;
      e.preventDefault();
      date = $(this).data('date').split('-');
      $('#collection_discounted_until_1i').val(date[0]);
      $('#collection_discounted_until_2i').val(date[1]);
      $('#collection_discounted_until_3i').val(date[2]);
      $('#collection_discounted_until_4i').val(date[3]);
      return $('#collection_discounted_until_5i').val(date[4]);
    });
    $('#collection_tag_list').tagEditor({
      sortable: false,
      autocomplete: {
        delay: 0,
        minLength: 2,
        autoFocus: true,
        source: function(request, response) {
          var matcher, matcher_test;
          matcher = new RegExp('^' + $.ui.autocomplete.escapeRegex(request.term), 'i');
          matcher_test = $.grep(tags, function(item, index) {
            return matcher.test(item);
          });
          return response(matcher_test.slice(0, 10));
        }
      }
    });
    $(".max_three_in_group_2 input").change(function() {
      if ($(".max_three_in_group_2 input:checked").length > 3) {
        return $(this).prop('checked', false);
      }
    });
    $("#collection_teaser_tab input[type='radio']").change(function() {
      if (($('video').length)) {
        videojs($('video').attr('id')).dispose();
      }
      return $.get($(this).next().find('span').data('url')).done(function() {
        return VideoJsWithRange.call();
      });
    });
    if ($('#collection_preview_images').data('job_id')) {
      CollectionPreviewsPoller.poll();
    }
    if ($('#collection_teaser').data('job_id')) {
      CollectionTeasersPoller.poll();
    }
    url = new URL(window.location);
    medium_id = url.searchParams.get('medium_id');
    if (medium_id) {
      $('#collection_medium_ids_' + medium_id).click();
    }
    return $('.use-complete-file').on('click', function(e) {
      var time;
      e.preventDefault();
      $(this).closest('div').find('input').click();
      time = new Date(null);
      time.setSeconds(0);
      $('#collection_start_of_teaser').val(time.toISOString().substr(11, 8));
      time = new Date(null);
      time.setSeconds($(this).data('duration'));
      return $('#collection_end_of_teaser').val(time.toISOString().substr(11, 8));
    });
  } else if ($('#admins-collections-table').length) {
    table = $('#admins-collections-table').DataTable({
      ajax: $('#admins-collections-table').data('source'),
      order: [0, 'desc'],
      dom: 'lrtip',
      columns: [
        {
          data: "created_at",
          responsivePriority: 1
        }, {
          data: "image",
          responsivePriority: 2,
          sortable: false
        }, {
          data: "name",
          responsivePriority: 1
        }, {
          data: "shop",
          responsivePriority: 3
        }, {
          data: "published_at",
          responsivePriority: 4
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_type: 'text'
      }, {
        column_number: 3,
        filter_container_id: 'td_3',
        filter_type: 'text'
      }, {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }
    ]);
  }
});
