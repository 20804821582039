document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#clients-table').length) {
    table = $('#clients-table').DataTable({
      ajax: $('#clients-table').data('source'),
      order: [4, 'desc'],
      columns: [
        {
          data: "name"
        }, {
          data: "payment_plan"
        }, {
          data: "pay_via"
        }, {
          data: "email"
        }, {
          data: "created_at",
          searchable: false
        }, {
          data: "valid_until",
          searchable: false
        }, {
          data: "current_login_at",
          searchable: false
        }, {
          data: "state"
        }, {
          data: "action",
          sortable: false,
          searchable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    yadcf.init(table, [
      {
        column_number: 7,
        filter_container_id: 'td_7',
        filter_default_label: "Select state",
        data: ['active', 'suspended']
      }
    ]);
    return yadcf.exFilterColumn(table, [[7, 'active']]);
  }
});
