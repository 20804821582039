document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#time-tracks-table').length) {
    table = $('#time-tracks-table').DataTable({
      ajax: $('#time-tracks-table').data('source'),
      order: [8, 'desc'],
      columns: [
        {
          data: "collection"
        }, {
          data: "medium"
        }, {
          data: "user"
        }, {
          data: "times"
        }, {
          data: "seconds"
        }, {
          data: "country"
        }, {
          data: "price"
        }, {
          data: "bill"
        }, {
          data: "updated_at",
          searchable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
