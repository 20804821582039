document.addEventListener('turbolinks:load', function() {
  var mark_selected_images, prepare_form_submit;
  prepare_form_submit = function() {
    var filenames;
    if ($('#collection_preview_images_selection div:empty').length >= 14) {
      $('#collection_preview_images_submit').prop('disabled', true);
      return $('#collection_preview_images_note').show();
    } else {
      filenames = $('#collection_preview_images_selection img').map(function() {
        return $(this).attr('src').replace(/(\d{3})\/(\d{3})\/(\d{3})/, '$1$2$3').replace(/.*(previews\/\d+)\/images/, '$1').replace(/.*(other_previews\/\d+)/, '$1').replace(/tiny_/, '').replace(/\?\d+$/, '');
      });
      $('#collection_filenames').val(filenames.get().join(','));
      $('#collection_preview_images_note').hide();
      return $('#collection_preview_images_submit').prop('disabled', false);
    }
  };
  mark_selected_images = function() {
    var filenames;
    filenames = $('#collection_preview_images_selection img').map(function() {
      return $(this).attr('src').replace(/\?\d+$/, '');
    });
    return $('#collection_preview_images_all .thumbnail').each(function() {
      if ($.inArray($(this).find('img').attr('src'), filenames) >= 0) {
        return $(this).addClass('selected');
      } else {
        return $(this).removeClass('selected');
      }
    });
  };
  if ($('#inner-body.collection_previews.edit, #inner-body.collection_previews.update').length) {
    new Dropzone("#new-poster", {
      paramName: 'poster[image]',
      dictDefaultMessage: $('#uploaded-posters').data('description'),
      maxFilesize: 5, // max megabytes
      acceptedFiles: 'image/jpeg',
      previewsContainer: '#uploaded-posters',
      previewTemplate: $('#dropzone-template').html(),
      success: function(file, serverResponse) {
        return this.emit('thumbnail', file, serverResponse.url);
      }
    });
    prepare_form_submit();
    mark_selected_images();
    $(window).scroll(function() {
      var scrollValue;
      scrollValue = $(window).scrollTop();
      if (scrollValue > 300) {
        $('.content-header').css('margin-bottom', $('#affix').outerHeight() + 'px');
        $('#affix').addClass('affix');
        $('#affix').width($('#collection_preview_images_all').width());
        return $('#affix').css('top', $('.main-header').outerHeight());
      } else {
        $('#affix').removeClass('affix');
        return $('.content-header').css('margin-bottom', '0');
      }
    });
    $(window).resize(function() {
      return $('.affix').width($('#collection_preview_images_all').width());
    });
    $("#collection_preview_images_selection").sortable({
      stop: function(event, ui) {
        return prepare_form_submit();
      }
    });
    $('#collection_preview_images_all').on('click', '.thumbnail', function() {
      var div, divs, img;
      if ($(this).hasClass('selected')) {
        img = $('#collection_preview_images_selection').find("img[src*='" + $(this).find('img').attr('src').replace(/.*previews\//, '') + "']");
        img.remove();
      } else {
        img = "<img src='" + $(this).find('img').attr('src') + "' />";
        div = $('#collection_preview_images_selection div:empty').first();
        if (div.length) {
          $(img).prependTo(div);
        } else {
          div = $("<div class='col col-md-1_5 col-xs-3'><div class='thumbnail'>" + img + "</div></div>");
          div.prependTo('#collection_preview_images_selection');
        }
      }
      divs = $("#collection_preview_images_selection .col");
      if (divs.length > 16) {
        divs.slice(16).map(function() {
          var src;
          src = $(this).find('img').attr('src');
          div = $('#collection_preview_images_all').find("img[src='" + src + "']").closest('.thumbnail');
          div.removeClass('selected');
          return $(this).remove();
        });
      }
      prepare_form_submit();
      return $(this).toggleClass('selected');
    });
    $('#remove_all').click(function() {
      $('#collection_preview_images_selection img').remove();
      $('#collection_preview_images_all .selected').removeClass('selected');
      return prepare_form_submit();
    });
    $('#autoselect_images').click(function() {
      var take_every;
      $('#collection_preview_images_selection img').remove();
      $('#collection_preview_images_all .selected').removeClass('selected');
      $('#uploaded-posters .thumbnail').each(function() {
        if ($('#collection_preview_images_selection img').length < 16) {
          return $(this).click();
        }
      });
      take_every = Math.round($('#uploaded-media .thumbnail').length / 16);
      $('#uploaded-media .thumbnail').filter(function(index, image) {
        if ((index % take_every) === 0 && $('#collection_preview_images_selection img').length < 16) {
          return $(image).click();
        }
      });
      return prepare_form_submit();
    });
    return $('#collection_preview_images_submit').click(function() {
      var elements;
      elements = $('#collection_preview_images_selection img');
      $('#collection_preview_images_selection div:empty').each(function() {
        var div, img;
        img = "<img src='" + elements.eq(Math.floor(Math.random() * elements.length)).attr('src') + "' />";
        div = $('#collection_preview_images_selection div:empty').first();
        if (div.length) {
          return $(img).prependTo(div);
        }
      });
      return prepare_form_submit();
    });
  }
});
