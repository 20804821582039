document.addEventListener('turbolinks:load', function() {
  if ($('#inner-body.accounts.edit, #inner-body.accounts.update').length) {
    if ($('#merchant_gets_paid_via').length) {
      $('#merchant_gets_paid_via').bind('change', (function(_this) {
        return function() {
          $('#bank_fields').hide();
          $('#bitcoin_fields').hide();
          $('#clarify_details').hide();
          $('#paypal_fields').hide();
          if ($('#merchant_gets_paid_via').val() === 'bank') {
            return $('#bank_fields').show();
          } else if ($('#merchant_gets_paid_via').val() === 'cryptocurrency') {
            return $('#bitcoin_fields').show();
          } else if ($('#merchant_gets_paid_via').val() === 'paypal') {
            return $('#paypal_fields').show();
          } else {
            return $('#clarify_details').show();
          }
        };
      })(this));
      return $('#merchant_gets_paid_via').change();
    }
  }
});
