document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#announcements-table').length) {
    table = $('#announcements-table').DataTable({
      ajax: $('#announcements-table').data('source'),
      order: [1, 'desc'],
      columns: [
        {
          data: "subject",
          responsivePriority: 1
        }, {
          data: "published_at",
          responsivePriority: 3
        }, {
          data: "valid_till",
          responsivePriority: 4
        }, {
          data: "text",
          responsivePriority: 5
        }, {
          data: "hide_from_non_subscribers",
          responsivePriority: 4,
          sortable: false
        }, {
          data: "action",
          responsivePriority: 2,
          sortable: false,
          searchable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
