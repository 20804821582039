document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#cancellation_reasons-table').length) {
    table = $('#cancellation_reasons-table').DataTable({
      ajax: $('#cancellation_reasons-table').data('source'),
      order: [0, 'asc'],
      columns: [
        {
          data: "name"
        }, {
          data: "action",
          sortable: false,
          searchable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
