document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#models-table').length) {
    table = $('#models-table').DataTable({
      ajax: $('#models-table').data('source'),
      order: [0, 'asc'],
      columns: [
        {
          data: "name",
          responsivePriority: 1
        }, {
          data: "collection",
          responsivePriority: 3
        }, {
          data: "available",
          responsivePriority: 4
        }, {
          data: "action",
          responsivePriority: 2,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
  if ($('#inner-body.models.new, #inner-body.models.create, #inner-body.models.edit, #inner-body.models.update').length) {
    return $("#sortable").sortable({
      handle: 'img'
    });
  }
});
