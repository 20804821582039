document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#discounts-table').length) {
    table = $('#discounts-table').DataTable({
      ajax: $('#discounts-table').data('source'),
      order: [4, 'desc'],
      columns: [
        {
          data: "state",
          responsivePriority: 2
        }, {
          data: "volume",
          responsivePriority: 1
        }, {
          data: "subject",
          responsivePriority: 3
        }, {
          data: "sales_count",
          responsivePriority: 5
        }, {
          data: "activate_at",
          responsivePriority: 2
        }, {
          data: "deactivate_at",
          responsivePriority: 4
        }, {
          data: "action",
          responsivePriority: 2,
          searchable: false,
          sortable: false
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_default_label: $('#discounts-table').data('label-status'),
        data: $('#discounts-table').data('status')
      }, {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 5,
        filter_container_id: 'td_5',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }
    ]);
  }
});
