document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#contents-table').length) {
    table = $('#contents-table').DataTable({
      ajax: $('#contents-table').data('source'),
      order: [0, 'asc'],
      lengthMenu: [[-1], ["All"]],
      columns: [
        {
          data: "order",
          responsivePriority: 1,
          sortable: false,
          className: 'reorder'
        }, {
          data: "menu_item",
          responsivePriority: 5,
          sortable: false
        }, {
          data: "title",
          responsivePriority: 3,
          sortable: false
        }, {
          data: "hide_from_non_subscribers",
          responsivePriority: 4,
          sortable: false
        }, {
          data: "action",
          responsivePriority: 2,
          searchable: false,
          sortable: false
        }
      ],
      rowReorder: {
        snapX: true,
        selector: 'i.fas'
      }
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    return table.on('row-reorder', function(e, diff, edit) {
      var result;
      result = $('#contents-table tbody tr').map(function() {
        return this.id.replace(/content_/, '');
      }).get();
      return $.ajax({
        type: 'POST',
        url: $('#contents-table').data('update'),
        data: {
          ids: result
        },
        async: false
      });
    });
  }
});
