document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#vouchers-table').length) {
    table = $('#vouchers-table').DataTable({
      ajax: $('#vouchers-table').data('source'),
      order: [0, 'desc'],
      columns: [
        {
          data: "created_at"
        }, {
          data: "payment_plan"
        }, {
          data: "client"
        }, {
          data: "used_at"
        }, {
          data: "voucher_url"
        }, {
          data: "action",
          searchable: false,
          sortable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
