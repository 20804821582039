document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#sales-table').length) {
    table = $('#sales-table').DataTable({
      ajax: $('#sales-table').data('source'),
      order: [0, 'desc'],
      dom: 'lrtip',
      columns: [
        {
          data: "created_at",
          searchable: false
        }, {
          data: "name"
        }, {
          data: "shop"
        }, {
          data: "pay_via"
        }, {
          data: "status"
        }, {
          data: "bill_id"
        }, {
          data: "price"
        }
      ]
    });
    table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
    table.on('draw', function() {
      return table.columns('.sum').every(function() {
        var sum;
        sum = this.data().length === 0 ? 0 : this.data().reduce(function(a, b) {
          if (typeof a === 'string') {
            a = a.replace(/[^\d-]/g, '') * 1;
          }
          if (typeof b === 'string') {
            b = b.replace(/[^\d-]/g, '') * 1;
          }
          return a + b;
        });
        return $(this.footer()).html((sum / 100).toLocaleString($('html').attr('lang'), {
          style: 'currency',
          currency: 'EUR'
        }));
      });
    });
    return yadcf.init(table, [
      {
        column_number: 0,
        filter_container_id: 'td_0',
        filter_type: 'range_date',
        date_format: 'yyyy-mm-dd'
      }, {
        column_number: 1,
        filter_container_id: 'td_1',
        filter_type: 'text'
      }, {
        column_number: 2,
        filter_container_id: 'td_2',
        filter_default_label: $('#sales-table').data('label-select-shop'),
        data: $('#sales-table').data('shops')
      }, {
        column_number: 3,
        filter_container_id: 'td_3',
        filter_default_label: $('#sales-table').data('label-payment-method'),
        data: $('#sales-table').data('payment-method')
      }, {
        column_number: 4,
        filter_container_id: 'td_4',
        filter_default_label: $('#sales-table').data('label-status'),
        data: $('#sales-table').data('status')
      }
    ]);
  }
});
