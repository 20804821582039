document.addEventListener('turbolinks:load', function() {
  var table;
  if ($('#download_tokens-table').length) {
    table = $('#download_tokens-table').DataTable({
      ajax: $('#download_tokens-table').data('source'),
      order: [2, 'desc'],
      columns: [
        {
          data: "collection"
        }, {
          data: "url"
        }, {
          data: "created_at"
        }, {
          data: "used_at"
        }, {
          data: "used_from"
        }, {
          data: "downloads",
          searchable: false,
          sortable: false
        }, {
          data: "action",
          searchable: false,
          sortable: false
        }
      ]
    });
    return table.on('page.dt', function() {
      return $('html, body').animate({
        scrollTop: 0
      }, 'slow');
    });
  }
});
